import React from "react";
// import { Grid } from "@material-ui/core";
// import MUIDataTable from "mui-datatables";

// components



// import ReactDOM from 'react-dom';
import { Form, Input, Select, Option, Button} from 'muicss/react';
import PageTitle from "../../components/PageTitle/PageTitle";


class RemoveUser extends React.Component {

  state = {
    emailValue: "",
    role: "disable"
  }
  searchForModerator = () => {
    let token = localStorage.getItem("id_token")
    let email = this.state.emailValue

    if(token !== null){
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token},
        body: JSON.stringify({type: 'email', search: email})
      };
      fetch('https://api.trillproject.com/v2/finduser', requestOptions)
      .then(async response => {
        const data = await response.json();
       
        if (!response.ok) {
          return Promise.reject('There was an error.');
        } else if(!data.success){
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        } else {
          if(data.data !== null){
            let id = data.data._id
            this.addModerator(id, function(success){
            })
          } else {
            alert('User not found.')
          }
         

        }
      })
      .catch(error => {
        alert(error)
      });
    } 
  }

  addModerator = (userID,  completion) => {
    let token = localStorage.getItem("id_token")
    var disable = true
    if(this.state.role !== "disable"){
      disable = false
    }

    if(token != null){
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': token},
        body: JSON.stringify({id: userID, disabled: disable})
      };
      console.log(userID)
      fetch('https://api.trillproject.com/v2/disable', requestOptions)
      .then(async response => {
        const data = await response.json();
       
        if (!response.ok) {
          return Promise.reject('There was an error.');
        } else if(!data.success){
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        } else {

          alert('User disabled sucessfully.')
          completion(true)
        }
      })
      .catch(error => {
        alert(error)
      });
    } 
  }
  render() {
    if(localStorage.getItem('id_token') === null && localStorage.getItem('id_token').length === 0){
      window.open('/login', "_self")
      return(<div></div>)
    }
 
    var select = <Select defaultValue="Admin" onChange={event => this.handleChange(event.target.value)}>
      <Option value="Disable" label="Disable" />
      <Option value="Enable" label="Enable" />
    </Select>

    return (
      <Form>
       <PageTitle title="Disable User" />
        <Input onChange={event => this.setInputValue(event.target.value)}  placeholder="Email" />
        {select}
        <Button onClick={this.searchForModerator} variant="raised">DONE</Button>
      </Form>
    );
  }
  setInputValue = (value) => {
    this.setState({
      emailValue: value
    })
  }
  handleChange = (value) => {
    console.log(value)
    if(value !== null){
      this.setState({role: value.toLowerCase()});
    }
  }
}
export default RemoveUser


