import React from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
// import Widget from "../../components/Widget/Widget";
// import Table from "../dashboard/components/Table/Table";

// data
// import mock from "../dashboard/mock";

class Moderators extends React.Component {
  state = {
    databaseData: [],
    databaseIDs: [],
    needsRefresh: false
  }
  
  render(){
    if(this.state.databaseData.length === 0|| this.state.needsRefresh === true){
      this.adminList()
      
    }
    return <>
      <PageTitle title="Moderators" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title="Administrator List"
            data={this.state.databaseData}
            columns={["Email", "Name/Color", "Role", "Score", "Community"]}
            options={{
              filterType: "checkbox",
              search: false,
              onRowsDelete: this.onDelete
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Widget title="Material-UI Table" upperTitle noBodyPadding>
            <Table data={mock.table} />
          </Widget>
        </Grid> */}
      </Grid>
    </>
  }
  onDelete = (rowsDeleted, otherData, newTableData) => {
    console.log(rowsDeleted)

    var data = rowsDeleted.data
    for(var i = 0; i < data.length; i++){
      let index = data[i].index
      let id = this.state.databaseIDs[index]
      // let email = this.state.databaseData[index][0]
      
      this.removeModerators(id, index, function(success){
        if(!success){
          this.setState({
            needsRefresh: true,
          })
    
        }
      })
      if(i === data.length - 1){
        this.setState({
          needsRefresh: true,
        })
      }
    }
  }

  removeModerators = (userID, index, completion) => {
    let token = localStorage.getItem("id_token")

    if(token != null){
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': token},
        body: JSON.stringify({id: userID, role: 'user'})
      };
      console.log(userID)
      fetch('https://api.trillproject.com/v2/updaterole', requestOptions)
      .then(async response => {
        const data = await response.json();
       
        if (!response.ok) {
          return Promise.reject('There was an error.');
        } else if(!data.success){
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        } else {
          var databaseData = this.state.databaseData
          databaseData.slice(index)
          var databaseIDs = this.state.databaseIDs
          databaseIDs.slice(index)
          this.setState({
            databaseData: databaseData,
            databaseIDs: databaseIDs,
          })
          completion(true)
        }
      })
      .catch(error => {
        alert(error)
        completion(false)
      });
    } 
  }

  adminList = () => {
    let token = localStorage.getItem("id_token")
    if(token != null){ 
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': token}
      };
      fetch('https://api.trillproject.com/v2/adminlist', requestOptions)
      .then(async response => {
        const data = await response.json();
       
        if (!response.ok) {
          return Promise.reject('There was an error.');
        } else if(!data.success){
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        } else {
          
          var databaseData = []
          var databaseIDs = []
          for(var i = 0; i < data.data.length; i++){
            let dataObj = data.data[i]
            var userObject = []
            
            if(dataObj.email != null){
              userObject.push(dataObj.email);
            } else {
              userObject.push("");
            }
            if(dataObj._id != null){
              databaseIDs.push(dataObj._id)
            } else {
              databaseIDs.push("")
            }
            if(dataObj.displayName.length > 0){
              userObject.push(dataObj.displayName.length > 0);
            } else if (dataObj.color != null){
              userObject.push(dataObj.color);
            } else {
              userObject.push("");
            }
            if(dataObj.role != null){
              userObject.push(dataObj.role);
            } else {
              userObject.push("");
            }
            if(dataObj.score != null){
              userObject.push(dataObj.score);
            } else {
              userObject.push("");
            }
            if(dataObj.community != null){
              userObject.push(dataObj.community)
            } else {
              userObject.push("")
            }
            databaseData.push(userObject)
            if(i === data.data.length - 1){
              this.setState({
                databaseIDs: databaseIDs
              })
              this.setState({
                databaseData: databaseData,
                needsRefresh: false
              })
            }
          }
  
        }
      })
      .catch(error => {
        alert(error)
      });
    } 
  }
  
}
export default Moderators